import React from 'react'
import Layout from '../../components/Layout'

const FormsPage = () => (
    <Layout>
        <section className="section section--gradient">
            <div className="container">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div className="section">
                            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                                Forms and Documents
                            </h2>
                            <p>
                                Below you will find our active bylaws and articles of incorporation, as well as forms you may need to submit
                                to the board. These forms can be submitted via the <a href="/contact">contact page</a> or hardcopy. If you have any questions, please use 
                                the <a href="/contact">contact page</a> or our email address <a href="mailto:woodlandgreensOH@gmail.com" target="_blank">woodlandgreensOH@gmail.com</a>.
                            </p>
                            <br/>
                            <div className="content">
                                <h3>Forms</h3>
                                <div>
                                    <p>
                                        <a href="/docs/WGHA_ImprovementApplication.pdf" download="WGHA_ImprovementApplication.pdf">Exterior Improvement Application </a>
                                        - Use this form to request approval from the Board of Directors before conducting any exterior improvements.
                                    </p>
                                    <p>
                                        <a href="/docs/WGHA_ComplaintForm.pdf" download="WGHA_Complaint.pdf">Complaint / Concern Form </a>
                                        - Homeowners are strongly encouraged to resolve issues with their neighbors. Please use this form to
                                        inform the Board of Directors of specific complaints/concerns you may need help resolving.
                                    </p>
                                </div>
                                <h3>Documents</h3>
                                <div>
                                    <p>
                                        <a href="/docs/WGHA_AoI.pdf" download="WGHA_ArticlesOfIncorporation.pdf">Articles of Incorporation - Effective 1987</a>
                                    </p>
                                    <p>
                                        <a href="/docs/WGHA_Covenants2019.pdf" download="WGHA_Covenants_2019.pdf">Covenants - Effective 1987</a>
                                    </p>
                                    <p>
                                        <a href="/docs/WGHA_ByLaws2007.pdf" download="WGHA_By-Laws_2007.pdf">By-Laws - Effective 2007</a>
                                    </p>
                                    <p>
                                        <a href="/docs/WGHA_ArchitecturalStandards_Feb_2024.pdf" download="WGHA_ArchitecturalStandards_Feb_2024.pdf">Architectural Standards - Effective 2024</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)

export default FormsPage
